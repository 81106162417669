import Head from 'next/head';
import { StateProvider } from 'src/lib/store/state';
import 'src/styles/carbon.scss';
import 'src/styles/globals.scss';
import Layout from 'src/components/global/layout';
import { SWRConfig } from 'swr';

export default function MyApp({ Component, pageProps }) {
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1, user-scalable=yes"
        />
        <meta name="description" content="Kyndryl Service Insights" />
        <meta name="keywords" content="Keywords" />
        <title>Kyndryl Service Insights</title>

        <link rel="manifest" href="/dashboards/manifest.json" />
        <link
          href="/dashboards/icons/icon-16x16.png"
          rel="icon"
          type="image/png"
          sizes="16x16"
        />
        <link
          href="/dashboards/icons/icon-32x32.png"
          rel="icon"
          type="image/png"
          sizes="32x32"
        />
        <link
          rel="apple-touch-icon"
          href="/dashboards/icons/icon-192x192.png"
        ></link>
        <meta name="theme-color" content="#fb4425" />
      </Head>

      <div>
        <SWRConfig
          value={{
            fetcher: (url) => fetch(url).then((res) => res.json())
          }}
        >
          <StateProvider>
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </StateProvider>
        </SWRConfig>
      </div>
    </>
  );
}
