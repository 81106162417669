import { createContext, useReducer } from 'react';
import getConfig from 'next/config';
const config = getConfig();
import _ from 'lodash';
const serverConfiguration = _.cloneDeep(config.publicRuntimeConfig);

const initialState = {
  menuOpen: true,
  user: {},
  account: {
    selectedFilters: {},
    migrated: false
  },
  dashboards: [],
  categorizedDashboards: {},
  widgets: [],
  dashboard: {},
  initialFetchAttemptedUser: false,
  initialFetchAttemptedDashboards: false,
  targetId: null
};

export const store = createContext(initialState);

const { Provider } = store;

export const StateProvider = ({ children }) => {
  const [globalState, dispatch] = useReducer((globalState, action) => {
    const currentState = { ...globalState };

    switch (action.type) {
      case 'TOGGLE_MENU':
        currentState.menuOpen = !currentState.menuOpen;
        return currentState;
      case 'CLOSE_MENU':
        currentState.menuOpen = false;
        return currentState;
      case 'SET_USER_FETCHED':
        currentState.initialFetchAttemptedUser = true;
        return currentState;
      case 'SET_USER':
        currentState.user = action.payload;
        return currentState;
      case 'SET_ACCOUNT_ID':
        currentState.account.id = action.payload;
        isMigrated(currentState);
        return currentState;
      case 'SET_TARGET_ID':
        currentState.targetId = action.payload;
        return currentState;
      case 'SET_ACCOUNT':
        action.payload.selectedFilters = currentState.account.selectedFilters;
        currentState.account = action.payload;
        isMigrated(currentState);
        return currentState;
      case 'SET_DASHBOARDS':
        currentState.dashboards = action.payload.dashboards;
        currentState.categorizedDashboards =
          action.payload.categorizedDashboards;
        currentState.widgets = action.payload.widgets;
        if (currentState.widgets?.length > 0) {
          currentState.menuOpen = false;
        }
        return currentState;
      case 'SET_DASHBOARDS_FETCHED':
        currentState.initialFetchAttemptedDashboards = true;
        return currentState;
      case 'SET_DASHBOARD':
        currentState.dashboard = action.payload;
        return currentState;
      case 'SET_SELECTED_FILTER':
        currentState.account.selectedFilters[action.payload.filterId] =
          action.payload.value;
        return currentState;
      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{ globalState, dispatch }}>{children}</Provider>;
};
function isMigrated(currentState) {
  const dxPlatform = serverConfiguration['dx-platform'];
  const migratedAccounts = dxPlatform.accounts;
  if (migratedAccounts.includes(currentState.account.id)) {
    currentState.account.migrated = dxPlatform.migrated;
    currentState.account.migratedUrl = dxPlatform.dxUrl;
  }
}

