import { useContext, useState, useEffect } from 'react';
import { store } from 'src/lib/store/state';
import { useRouter } from 'next/router';
import Link from 'next/link';
import useDashboards from 'src/lib/hooks/useDashboards';
import KDLoader from 'src/components/reusable/loader';
import { buildQs } from 'src/lib/helpers/helpers';

const KDMenu = (props) => {
  const router = useRouter();

  // access global state context
  const { globalState, dispatch } = useContext(store);
  const accountQs = buildQs(globalState);

  // call the dashboards api hook
  const { dashboards, dashboardsLoading, dashboardsError } = useDashboards();

  const handleLink = (e, item) => {
    //   e.preventDefault();

    // close the menu
    dispatch({ type: 'CLOSE_MENU' });

    //   // update global state selected dashboard
    //   dispatch({ type: 'SET_DASHBOARD', payload: item });
    //   // update url query string
    //   const PATH = buildQs(globalState, false, item);
    //   router.replace(PATH);
  };

  const [rendered, setRendered] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (globalState.menuOpen) {
      setRendered(true);
      setTimeout(() => {
        setExpanded(true);
      }, 10);
    } else {
      setExpanded(false);
      setTimeout(() => {
        setRendered(false);
      }, 150);
    }
  }, [globalState.menuOpen]);

  return (
    <nav
      className={`
        ${rendered ? 'rendered' : ''}
        ${expanded ? 'expanded' : ''}
      `}
    >
      <KDLoader
        visible={dashboardsLoading && globalState.account.id}
        position={globalState.dashboards.length ? 'right' : 'center'}
      />

      {globalState.dashboards.length ? (
        <div className="categories">
          {/* loop through categories */}
          {Object.keys(globalState.categorizedDashboards).map((key) => (
            <div key={key}>
              <strong>{key}</strong>
              <ul>
                {/* loop through links in category */}
                {globalState.categorizedDashboards[key].map((item, index) => (
                  <li key={item.id}>
                    <Link
                      href={`/dashboard/${encodeURIComponent(
                        item.id
                      )}${accountQs}`}
                    >
                      <a onClick={(e) => handleLink(e, item)}>{item.name}</a>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          
          {globalState.account.id == 'ibm-catalyst' && (
            <div>
              <strong>Account View - Invoices PowerBI Dashboard</strong>
              <ul>
                <li>
                  <Link
                    href="https://app.powerbi.com/groups/3417651f-b687-49b0-a751-9833f59cc9fd/reports/0b45ad26-7c67-46c8-9e5f-ea3f8be22e6b/ReportSectiona30c5d3adb093b76bd25"
                    target="_blank"
                    rel="noopener"
                  >
                    Invoice Report Dashboard - (Login with Kyndryl ID)
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </div>
      ) : (
        [
          (globalState.initialFetchAttemptedDashboards &&
            globalState.user.userEmail &&
            !dashboardsError && (
              <div className="no-dashboards">No dashboards found.</div>
            ),
          dashboardsError && globalState.user.userEmail && (
            <div className="no-dashboards">Error loading dashboards.</div>
          ))
        ]
      )}
    </nav>
  );
};

export default KDMenu;
