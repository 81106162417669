const KDLoader = (props) => {
  return (
    <div
      className={`
        loader-container
        rendered
        ${props.visible ? 'visible' : ''}
        position--${props.position}
      `}
    >
      <div className="loader"></div>
    </div>
  );
};

export default KDLoader;
