const { isArray } = require('lodash');

export const isEmptyObject = (obj) => {
  return obj && obj.constructor === Object && Object.keys(obj).length === 0;
};

export const sortObjectByKey = (obj) =>
  Object.fromEntries(Object.entries(obj).sort());

export const buildQs = (globalState, isRoot, item) => {
  if (isRoot) {
    return globalState.account.id
      ? globalState.targetId
        ? '/dashboards?accountId=' +
        globalState.account.id +
        '&targetId=' +
        removeDashes(globalState.targetId)
        : '/dashboards?accountId=' + globalState.account.id
      : '/dashboards';
  } else {
    return globalState.targetId
      ? '?accountId=' +
      globalState.account.id +
      '&targetId=' +
      removeDashes(globalState.targetId)
      : '?accountId=' + globalState.account.id;
  }
};

export const buildMatchPhrase = (filters) => {
  if (!filters) {
    return;
  }

  let arr = [];
  for (const [name, value] of Object.entries(filters)) {
    if (!value || !name) {
      continue;
    }

    value.id = !isArray(value.id) ? [value.id] : value.id;

    // Filter (And Condition)
    value.id.forEach(x => {
      arr.push({
        match_phrase: {
          [name]: x
        }
      });
    });

    // shouldFilter (OR Condition) - %map% execution. With minimum_should_match is 1, it will act as AND condition if one value is present.
    if (value.shouldFilterArr?.length > 0) {
      let shouldFilterObj = {
        "bool": {
          "should": [],
          "minimum_should_match": 1
        }
      };

      value.shouldFilterArr.forEach(x => {
        shouldFilterObj.bool.should.push({
          match_phrase: {
            [name]: x
          }
        });
      });

      arr.push(shouldFilterObj);
    }
  }

  return arr;
};

export const restoreDashes = (guid) => {
  return guid
    .trim()
    .replace(
      /([a-zA-Z0-9]{8})([a-zA-Z0-9]{4})([a-zA-Z0-9]{4})([a-zA-Z0-9]{4})([a-zA-Z0-9]{12})/,
      '$1-$2-$3-$4-$5'
    );
};

export const removeDashes = (guid) => {
  return guid.split('-').join('');
};
