import { useContext, useEffect } from 'react';
import { store } from 'src/lib/store/state';
import useSWR from 'swr';
import { sortObjectByKey } from 'src/lib/helpers/helpers';

export default function useDashboards({} = {}) {
  const { globalState, dispatch } = useContext(store);

  // inject support mode header if targetId is present
  let fetcherConfig = {};
  if (globalState.targetId && globalState.targetId.length == 36) {
    fetcherConfig = {
      headers: {
        'x-ibm-target-consolidated-acct': globalState.targetId
      }
    };
  }
  const fetcher = (url) => fetch(url, fetcherConfig).then((res) => res.json());

  // fetch dashboards api
  const { data: dashboards, error: dashboardsError } = useSWR(
    globalState.account.id && globalState.user.userEmail
      ? '/dashboards/api/proxy/dashboards/dashboards/v1/dashboard?expand=widget&account=' +
          globalState.account.id
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      shouldRetryOnError: false,
      revalidateOnReconnect: false
    }
  );

  const dashboardsLoading = !dashboards && !dashboardsError;

  useEffect(() => {
    if (!dashboards) return;

    if (!globalState.initialFetchAttemptedDashboards) {
      dispatch({ type: 'SET_DASHBOARDS_FETCHED' });
    }

    if (Array.isArray(dashboards) && !globalState.dashboards.length) {
      // organize dashboards by category
      const categorizedDashboards = categorizeDashboards(dashboards);
      const widgets = extractWidgets(dashboards);

      // add label field to each dashboard for use in carbon combo box
      dashboards.map((dashboard) => {
        dashboard.label = dashboard.name;
      });

      // update dashboards in global state
      dispatch({
        type: 'SET_DASHBOARDS',
        payload: {
          dashboards: dashboards,
          categorizedDashboards: categorizedDashboards,
          widgets: widgets
        }
      });
    }
  }, [dashboards, globalState, dispatch]);

  return { dashboards, dashboardsLoading, dashboardsError };
}

// function to organize data array by category. outputs an object where keys = category name
const categorizeDashboards = (menuItems) => {
  let categorizedItems = {};

  // loop through dashboards array
  for (let i = 0; i < menuItems.length; i++) {
    let menuItem = menuItems[i];

    // set category to 'Uncategorized' if not present
    if (!menuItem.category) {
      menuItem.category = 'Uncategorized';
    }

    const category = menuItem.category;

    // add dashboards to matching category array
    if (categorizedItems[category]) {
      categorizedItems[category].push(menuItem);
    } else {
      categorizedItems[category] = [menuItem];
    }
  }

  // sort the categories
  categorizedItems = sortObjectByKey(categorizedItems);

  return categorizedItems;
};

const extractWidgets = (dashboards) => {
  let widgets = [];

  for (let i = 0; i < dashboards.length; i++) {
    const dashboard = dashboards[i];

    if (dashboard.widgets) {
      for (let j = 0; j < dashboard.widgets.length; j++) {
        const widget = dashboard.widgets[j];

        // add needed info from parent dashboard object
        widget.dashboardId = dashboard.id;
        widget.category = dashboard.category;

        widgets.push(widget);
      }
    }
  }

  // sort by category
  widgets.sort((a, b) => {
    return a.category.localeCompare(b.category);
  });

  return widgets;
};
