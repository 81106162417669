import { useContext, useEffect } from 'react';
import { store } from 'src/lib/store/state';
import router from 'next/router';
import useSWR from 'swr';

export default function useUser({} = {}) {
  const { globalState, dispatch } = useContext(store);

  // fetch user details api
  const { data: user, error: userError } = useSWR(
    globalState.account.id
      ? '/dashboards/api/user?accountId=' + globalState.account.id
      : null,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      shouldRetryOnError: false,
      revalidateOnReconnect: false
    }
  );

  const userLoading = !user && !userError;

  useEffect(() => {
    if (!user) return;

    if (!globalState.initialFetchAttemptedUser) {
      dispatch({ type: 'SET_USER_FETCHED' });
    }

    if (!user.userEmail) {
      // redirect to login if no user details
      const returnUrl = encodeURIComponent(router.basePath + router.asPath);
      window.location = '/dashboards/api/auth/login?return=' + returnUrl;
      return false;
    } else if (user.userEmail != globalState.user.userEmail) {
      // add label field to each account for use in carbon combo box
      // add (Support Mode) label for impersonated accounts
      // strip dashes from impersonated account IDs
      user.accounts.map((account) => {
        const LABEL =
          globalState.targetId && account.isImpersonated
            ? account.name + ' (Support Mode)'
            : account.name;
        account.label = LABEL;
      });
      // update global state user details
      dispatch({ type: 'SET_USER', payload: user });

      // inject support mode header if targetId is present
      let fetcherConfig = { method: 'POST' };
      if (globalState.targetId && globalState.targetId.length == 36) {
        fetcherConfig.headers = {
          'x-ibm-target-consolidated-acct': globalState.targetId
        };
      }

      // perform security login
      fetch(
        '/dashboards/api/proxy/dashboards/dashboards/v1/security/login',
        fetcherConfig
      );

      // syncronize policy
      fetch(
        '/dashboards/api/proxy/dashboards/dashboards/v1/policy/synchronize',
        fetcherConfig
      );
    }
  }, [user, globalState, dispatch]);

  return { user, userLoading, userError };
}
