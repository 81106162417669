import KDHeader from 'src/components/global/header';
import KDMenu from 'src/components/global/nav';

const Layout = ({ children }) => {
  return (
    <>
      <KDHeader />
      <KDMenu />
      <main>{children}</main>
    </>
  );
};

export default Layout;
